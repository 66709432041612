import React, { useState, useEffect } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Button, Typography, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useAxios } from '../../lib/axios';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.common.white,
  paddingLeft: 16,
  paddingRight: 16,
  borderRight: '2px solid rgba(255, 255, 255, 0.12)',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  borderLeft: `4px solid transparent`,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
}));

const columns = [
  { id: 'rowNumber', label: '#', minWidth: 100 },
  { id: 'code', label: 'Code', minWidth: 100 },
  { id: 'LOC', label: 'LOC', minWidth: 100 },
];

export default function SearchTable() {
  const navigate = useNavigate();
  const axios = useAxios();
  const [username, setUsername] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchInput, setSearchInput] = useState({
    sku_code: '',
    shelf_code: '',
    page: 1,
    page_size: 10,
    order_by: 'ASC'
  });
  const [rows, setRows] = useState([]);
  const [totalElements, setTotalElements] = useState(0); // จำนวนข้อมูลทั้งหมด
  const [totalPages, setTotalPages] = useState(0); // จำนวนหน้าทั้งหมด
  const [searchInitiated, setSearchInitiated] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSearchInput({ ...searchInput, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setSearchInput({ ...searchInput, page_size: newRowsPerPage, page: 1 });
  };

  const handleSearchChange = (event) => {
    setSearchInput({ ...searchInput, [event.target.name]: event.target.value });
  };

  const handleOrderChange = (event) => {
    setSearchInput({ ...searchInput, order_by: event.target.value });
  };

  const handleSearch = async () => {
    const updatedSearchInput = { ...searchInput, page_size: rowsPerPage };
    console.log('Search Criteria:', updatedSearchInput);
    
    await axios.post('/stocks/search', updatedSearchInput)
      .then((response) => {
        const data = response.data.content;
        
        // Set rows, totalElements, and totalPages from the API response
        const mappedRows = data.map((item, index) => ({
          rowNumber: index + 1,
          code: item.item.itemCode,
          LOC: item.shelf.shelfName,
        }));

        setRows(mappedRows);
        setTotalElements(response.data.totalElements); // จำนวนข้อมูลทั้งหมด
        setTotalPages(response.data.totalPages);       // จำนวนหน้าทั้งหมด
      })
      .catch((error) => {
        console.log('Full error object:', error);
        console.error('Error response data:', error.response?.data);

        const errorMessage = error.response?.data?.message || 'กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่';
        Swal.fire({
          title: 'ไม่สามารถดำเนินการได้',
          text: errorMessage,
          icon: 'error',
          confirmButtonText: 'ตกลง',
          showCloseButton: false,
          showCancelButton: false,
        }).then(() => {
          // navigate('/menu')
        });
      });
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const { username, expiration } = JSON.parse(storedUserData);
      if (expiration && Date.now() < expiration) {
        setUsername(username);
      } else {
        localStorage.removeItem('userData');
        navigate('/');
      }
    } else {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    if (searchInitiated) {
      handleSearch();
    }
  }, [rowsPerPage, searchInitiated, searchInput.page]);

  const handleSearchButtonClick = () => {
    setSearchInitiated(true);
    handleSearch();
  };

  return (
    <Paper sx={{ width: '100%', overflowX: 'auto' }}>
      <Box sx={{ padding: '20px', paddingBottom: '10px', borderBottom: '1px solid #ccc', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Chip component="a" href="/menu" label="Menu" icon={<HomeIcon fontSize="small" />} />
          <Chip component="a" href="/menu/system" label="System" />
          <Chip label="Location(Dashboard)" deleteIcon={<ExpandMoreIcon />} />
        </Breadcrumbs>
        <Box>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>{username}</Typography>
        </Box>
      </Box>
      <div style={{ padding: '20px', paddingBottom: '10px', borderBottom: '1px solid #ccc', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '10px' }}>
          <TextField id="sku_code" name="sku_code" label="SKU Code" variant="outlined" fullWidth style={{ marginRight: '10px', flex: '1 0 100px' }} value={searchInput.sku_code} onChange={handleSearchChange} />
          <TextField id="shelf_code" name="shelf_code" label="Shelf Code" variant="outlined" fullWidth style={{ marginRight: '10px', flex: '1 0 100px' }} value={searchInput.shelf_code} onChange={handleSearchChange} />
          <FormControl>
            <FormLabel id="order-label">Order by</FormLabel>
            <RadioGroup row aria-labelledby="order-label" name="order_by" value={searchInput.order_by} onChange={handleOrderChange}>
              <FormControlLabel value="ASC" control={<Radio />} label="Ascending" />
              <FormControlLabel value="DESC" control={<Radio />} label="Descending" />
            </RadioGroup>
          </FormControl>
          <Button variant="contained" onClick={handleSearchButtonClick}>Search</Button>
        </div>
      </div>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell key={column.id} align="center">
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.code} hover>
                {columns.map((column) => (
                  <TableCell key={column.id} align="center">
                    {row[column.id]}
                  </TableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalElements}  // ใช้ totalElements จาก API
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
